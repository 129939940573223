interface EnvironmentConfig {
  production: boolean;
  sentry: {
    dsn: string;
  };
  matomo: {
    trackerUrl: string;
    siteId: string;
  };
  redirectUri: string;
}

export const environment: EnvironmentConfig = {
  production: true,
  sentry: {
    dsn: 'https://dbd623370abd4c04978256bb06e22a1c@o1306495.ingest.sentry.io/6549197',
  },
  matomo: {
    trackerUrl: 'https://consalio.matomo.cloud/',
    siteId: '1',
  },
  redirectUri: 'https://dev.consalio.com',
};
